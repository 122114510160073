import loadable from "@loadable/component"
import { Link, navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import { Configure, InstantSearch } from "react-instantsearch-dom"
import FilterSearch from "../components/FilterSearch/FilterSearch"
import Layout from "../components/Layout/Layout"
import ConnectFields from "../components/SearchResults/ConnectFields"
import SearchPagination from "../components/SearchResults/SearchPagination"
import StatAndSort from "../components/SearchResults/StatAndSort"
import CustomInfiniteHitsOP from "../components/SearchResults/offplan/CustomInfiniteHitsOP"
import CustomNoHitsOP from "../components/SearchResults/offplan/CustomNoHitsOP"
import "../components/SearchResults/search-results.scss"
import {
  INDEX_NAME,
  MAP_TOGGLE,
  SEARCH_CLIENT,
  createURL,
  getH1Text,
  getSeoDesc,
  searchStateToUrl,
  urlToSearchState,
} from "../components/SearchResults/utils"
import clsx from "clsx"
import SEO from "../components/Seo/seo"
import { contactURL } from "../site/urls"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
const SearchSideMap = loadable(() =>
  import("../components/SearchResults/map/RenderMap")
)

const OffplanResultsTemplate = props => {
  const { phone } = useCompanyInfo()
  const {isLargeScreen}=useDeviceMedia()
  const { location, pType, pStatus, pCategoryType, locationName, pSubPath } =
    props

  const [searchState, setSearchState] = useState(
    urlToSearchState(location, pSubPath)
  )

  const debouncedSetStateRef = useRef(null)

  const [showMap, setShowMap] = useState(false)
  const [showDesc, setShowDesc] = useState(false)
  const handleShowMapGrid = key => {
    setShowMap(key === MAP_TOGGLE.map)
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location, pSubPath))
  }, [location])

  const onSearchStateChange = searchState => {
    clearTimeout(debouncedSetStateRef.current)
    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(props, searchState, locationName), searchState)
    }, 500)
    setSearchState(searchState)
  }

  const h1Text = getH1Text({
    searchState,
    pType,
    isOffplan: true,
    capitalize: true,
  })
  const seoDescription = getSeoDesc({ searchState, pType, isOffplan: true })
  const infoText = getH1Text({ searchState, pType, isOffplan: true, capitalize: false })

  let finalInfoText = (
    <>
      Explore our range of {infoText}. <Link to={contactURL}>Contact Allegiance Real Estate.</Link> 
    </>
  )

  return (
    <>
      <SEO title={h1Text} description={seoDescription} />
      <InstantSearch
        indexName={INDEX_NAME}
        searchClient={SEARCH_CLIENT}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        routing="true"
      >
        <Configure
          hitsPerPage={showMap ? 1000 : 8}
          page={0}
          filters={`publish:true AND search_type:${pType} AND department:${pCategoryType} AND status:"${pStatus}"`}
        />
        <Layout className="search-results-page offplan-results-page" popularSearch="category1">
        {!isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }
          <FilterSearch searchState={searchState} pType={pType} isOffplan />
          <Container className="search-results-wrapper offplan-results-wrapper">
            <div
              className={clsx(
                "search-results-section offplan-results-section",
                showMap && "map-section-view"
              )}
            >
              <h1 className="search-title">{h1Text}<i className="icon-info" onClick={() => setShowDesc(!showDesc)}></i></h1>
              {showDesc && <p className="search-text">{finalInfoText}</p>}

              <StatAndSort
                showMap={showMap}
                handleShowMapGrid={handleShowMapGrid}
                isOffplan
              />
              {!showMap && (
                <>
                  <CustomInfiniteHitsOP />
                  <CustomNoHitsOP
                    status={pStatus}
                    department={pCategoryType}
                    search_type={pType}
                  />
                  <SearchPagination />
                </>
              )}
            </div>
          </Container>
          <SearchSideMap showMap={showMap} isOffplan />
        </Layout>
        <ConnectFields />
      </InstantSearch>
    </>
  )
}

export default OffplanResultsTemplate
