import React from "react"
import { offplanForSaleURL } from "../../site/urls"
import OffplanResultsTemplate from "../../templates/offplan-results-template"

const PropertySale = props => {
  const location = props.location

  return (
    <OffplanResultsTemplate
      locationName={offplanForSaleURL}
      location={location}
      pCategoryType="new_developments"
      pType="sales"
      pSubPath="for-sale"
      pStatus="For Sale"
    />
  )
}

export default PropertySale
