import _ from "lodash"
import React from "react"
import { connectHits } from "react-instantsearch-dom"
import PropertyCardOffplan from "../../PropertyCardOffplan/PropertyCardOffplan"
// import { useAutoAnimate } from "@formkit/auto-animate/react"
import {
  inViewOptions2,
  contentItemStagger
} from "../../utils/animation"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const InfiniteHitsOP = ({ hits }) => {
  // const [parent] = useAutoAnimate({ duration: 500 })

  if (_.isEmpty(hits)) return null

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        
    <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger} className="offplan-results-list-wrapper">
      {hits.map(hit => (
        <PropertyCardOffplan
          key={hit.objectID}
          type={hit.title}
          building_type={hit.building[0]}
          address={hit.display_address}
          description={hit.description}
          slug={hit.slug}
          developer_name={hit?.developer_name}
          image={hit.images[0]}
          id={hit.objectID}
          offplanData={{display_address: hit.display_address, negotiator_details: hit.negotiator_details, completion_status: hit.completion_status}}
        />
      ))}
       </motion.div>
      )}
    </InView>
   
  )
}

const CustomInfiniteHitsOP = connectHits(InfiniteHitsOP)

export default CustomInfiniteHitsOP
