import { Link } from "gatsby"
import React from "react"
import useCompanyInfo from "../../hooks/useCompanyInfo"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { bookViewing, offplanForSaleDetailURL } from "../../site/urls"
import { getWhatsAppURL } from "../common/site/utils"
import "./PropertyCardOffplan.scss"
import { brandSeoSuffix } from "../../site/constants"
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import GetGGFXImage from "../common/site/GetGGFXImage"
import noImg from "../../images/no-image.png"
import { fadeInFromTop } from "../utils/animation"
import { motion } from "framer-motion"
import voca from "voca"

const PropertyCardOffplan = ({
  offplanData,
  image,
  address,
  type,
  description,
  slug,
  developer_name,
  id,
  building_type,
  imagetransforms,
}) => {
  const title = address?.split(",")[0]
  const location = address?.split(",")[1]

  const { email, phone, name } = useCompanyInfo()

  const { isMobile } = useDeviceMedia()

  const shareurl = typeof window !== "undefined" ? window.location.href : ""
  const propertyURL=typeof window !== "undefined" ? `${window.location.origin}${offplanForSaleDetailURL}${slug}-${id}/` : ""


  let processedImages = imagetransforms?.images_Transforms
  processedImages ||= JSON.stringify({})



  return (
    <motion.div variants={fadeInFromTop} className="offplan-property-card-wrap">
      <div className="offplan-property-card">
        <Link
          to={offplanForSaleDetailURL + slug + "-" + id + "/"}
          className="image-section img-zoom"
        >
          {image ? (
            image.url ? (
              <GetGGFXImage
                imagename={"property.images.offplanTileImg"}
                imagesource={image}
                fallbackalt={title + brandSeoSuffix}
                imagetransformresult={processedImages}
                id={id}
                className="offplan-card-img"
              />
            ) : (
              <ShowProcessedImage
                images={image}
                attr={{
                  className: "offplan-card-img",
                  alt: title + brandSeoSuffix,
                }}
                transforms={imageConfig.property.offplanResults.sizes}
              />
            )
          ) : (
            <img src={noImg} className="offplan-card-img" alt="no image" />
          )}

          <div className="image-tag">special intro offer</div>
        </Link>
        <div className="content-section">
        <Link
          to={offplanForSaleDetailURL + slug + "-" + id+"/"}
        ><p className="offplan-title">{title}</p>
        </Link>
           
          <p className="ofplan-subtitle">{type}</p>
          <div className="offplan-card-details">
            <div className="offplan-card-detail">
              <p className="offplan-card-detail-title">Location:</p>
              <p className="offplan-card-detail-value">{location}</p>
            </div>
            <div className="offplan-card-detail">
              <p className="offplan-card-detail-title">Developer:</p>
              <p className="offplan-card-detail-value">{developer_name}</p>
            </div>
            <div className="offplan-card-detail development-type">
              <p className="offplan-card-detail-title">Development Type:</p>
              <p className="offplan-card-detail-value">{voca.capitalize(building_type)}</p>
            </div>
            <div className="offplan-card-detail">
              <p className="offplan-card-detail-title">
                Project Status & Handover:
              </p>
              <p className="offplan-card-detail-value">{voca.capitalize(voca.replace(offplanData.completion_status, '_', ' '))}</p>
            </div>
          </div>
          {!isMobile && (
            <>
              <p className="offplan-description">{description}</p>
              <div className="cta-section">
                <a
                  href={`tel:${phone}`}
                  className="button button-black search-button"
                  target={"_blank"}
                >
                  <i className="icon icon-phone"></i>
                  <span>Call</span>
                </a>
                <Link
                  to={bookViewing}
                  state={{
                    data: {
                      property: offplanData,
                      propertyURL,
                    },
                  }}
                  className="button button-black search-button"
                >
                  <i className="icon icon-email"></i>
                  <span>Email</span>
                </Link>
                <a
                  href={getWhatsAppURL(phone)}
                  className="button button-black search-button"
                  target={"_blank"}
                >
                  <i className="icon icon-whatsapp"></i>
                  <span>Whatsapp</span>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default PropertyCardOffplan
